import React, { useState } from "react";

import classnames from "classnames";

import castArray from "lodash/castArray";
import uniq from "lodash/uniq";

import { FaCopy } from "react-icons/fa";
import { BsCheckCircle } from "react-icons/bs";
import { ImSun } from "react-icons/im";
import { IoMoonSharp } from "react-icons/io5";
import { MdMailOutline, MdArtTrack } from "react-icons/md";
import { VscFileCode } from "react-icons/vsc";

import VIEW_MODE from "../../constants/viewModes";

const Icon = ({ icon: Component, lightDark, classes, color }) => (
    <Component
        className={`w-6 h-6 inline align-bottom ${classes}`}
        color={color ?? (lightDark ? "white" : "black")}
    />
);

const baseButtonClasses = "rounded border-2 border-transparent py-1 px-3 mx-1";

const UIButton = ({ children, classes, showActive, activeBorder, ...rest }) => (
    <button
        type="button"
        className={classnames(
            baseButtonClasses,
            {
                "bg-gray-200 text-gray-900": showActive,
                "border-yellow-500": showActive && activeBorder,
                "bg-gray-800 hover:bg-gray-600 text-gray-300 hover:text-gray-100": !showActive,
                "hover:border-gray-300": !showActive && activeBorder
            },
            classes
        )}
        {...rest}
    >
        {children}
    </button>
);

const copyToClipboard = (content) => {
    navigator.clipboard.writeText(content).then(
        function () {
            console.log("Copying to clipboard was successful!");
        },
        function (err) {
            console.error("Could not copy text: ", err);
        }
    );
};

let copyCodeTimeout = null;

const PreviewBar = ({
    viewMode,
    setViewMode,
    darkMode,
    setDarkMode,
    deployedOn,
    deployedBy,
    tags,
    html
}) => {
    const [copyCodeClicked, setCopyCodeClicked] = useState(false);

    return (
        <div className=" p-1 bg-gray-500 border-b-8 border-black">
            <div className="grid grid-cols-1 lg:grid-cols-3 justify-items-center items-center">
                <div className="justify-self-start">
                    {/* <div className="col-start-1 col-end-4">
                            <button
                                type="button"
                                className="rounded bg-gray-800 text-gray-300 py-1 px-3 mx-1"
                            >
                                Desktop
                            </button>
                            <button
                                type="button"
                                className="rounded bg-gray-800 text-gray-300 py-1 px-3 mx-1"
                            >
                                Tablet
                            </button>
                            <button
                                type="button"
                                className="rounded bg-gray-800 text-gray-300 py-1 px-3 mx-1"
                            >
                                Mobile
                            </button>
                        </div> */}
                    <div>
                        <UIButton
                            onClick={() => setDarkMode((val) => !val)}
                            // classes={!darkMode && "bg-gray-200 text-gray-900"}
                            showActive={!darkMode}
                        >
                            {darkMode ? "Dark" : "Light"} Mode{" "}
                            <Icon
                                icon={darkMode ? IoMoonSharp : ImSun}
                                color={darkMode ? "SlateBlue" : "Orange"}
                            />
                        </UIButton>
                    </div>
                </div>
                <div className="items-center hidden xl:block">
                    <UIButton
                        onClick={() => setViewMode(VIEW_MODE.mail)}
                        showActive={viewMode === VIEW_MODE.mail}
                        activeBorder={viewMode === VIEW_MODE.mail}
                    >
                        Just email{" "}
                        <Icon icon={MdMailOutline} lightDark={viewMode !== VIEW_MODE.mail} />
                    </UIButton>
                    <UIButton
                        onClick={() => setViewMode(VIEW_MODE.both)}
                        showActive={viewMode === VIEW_MODE.both}
                        activeBorder={viewMode === VIEW_MODE.both}
                        classes="text-lg"
                    >
                        <Icon
                            icon={MdArtTrack}
                            classes="w-7 h-7"
                            lightDark={viewMode !== VIEW_MODE.both}
                        />{" "}
                        View both
                    </UIButton>
                    <UIButton
                        onClick={() => setViewMode(VIEW_MODE.code)}
                        showActive={viewMode === VIEW_MODE.code}
                        activeBorder={viewMode === VIEW_MODE.code}
                    >
                        <Icon icon={VscFileCode} lightDark={viewMode !== VIEW_MODE.code} /> Just
                        code
                    </UIButton>
                </div>

                <div className="justify-self-end">
                    <button
                        type="button"
                        onClick={() => {
                            clearTimeout(copyCodeTimeout);
                            setCopyCodeClicked(true);
                            copyCodeTimeout = setTimeout(() => setCopyCodeClicked(false), 4000);
                            copyToClipboard(html);
                        }}
                        className={classnames("rounded py-1 px-3 mx-1 duration-500 ease-in-out", {
                            "transition-none bg-green-400 text-white": copyCodeClicked,
                            "transition-colors bg-gray-800 hover:bg-gray-600 text-gray-300 hover:text-gray-100": !copyCodeClicked
                        })}
                    >
                        {!copyCodeClicked ? (
                            <>
                                <Icon icon={FaCopy} lightDark={true} /> Copy code
                            </>
                        ) : (
                            <>
                                <Icon icon={BsCheckCircle} lightDark={true} /> Code copied!
                            </>
                        )}
                    </button>

                    <div className="inline-flex items-center">
                        {deployedOn ? (
                            <span className="rounded-full uppercase bg-yellow-200 text-yellow-600 py-0.5 px-2 mx-1 text-sm">
                                Platform: {deployedOn}
                            </span>
                        ) : null}
                        {deployedBy ? (
                            <span className="rounded-full uppercase bg-indigo-200 text-indigo-600 py-0.5 px-2 mx-1 text-sm">
                                Release: {deployedBy}
                            </span>
                        ) : null}
                        {tags &&
                            uniq(castArray(tags)).map((tag) => (
                                <span
                                    key={tag}
                                    className="rounded-full uppercase bg-gray-200 text-grey-600 py-0.5 px-2 mx-1 text-sm"
                                >
                                    {tag}
                                </span>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PreviewBar;
