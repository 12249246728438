import React, { useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import styled from "styled-components";

import filter from "lodash/filter";
import first from "lodash/first";
import find from "lodash/find";

import BreadCrumbs from "../components/BreadCrumbs";
import PreviewBar from "../components/PreviewBar";

import categories from "../constants/emailCategories";
import VIEW_MODE from "../constants/viewModes";
import ScopedTheme from "../components/ScopedTheme";

import dirPath from "../util/templateDirPath";
import { privatePathPrefix } from "../constants/privatePathPrefix";

const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-rows: auto auto 1fr;
`;

const EmailPreviewUI = ({
    pageContext: { slug, nextSlug, prevSlug, order, brandConfig },
    data: {
        mjml: {
            brand,
            html,
            frontmatter: { title, deployedOn, deployedBy, tags, category },
            subBrand
        }
    }
}) => {
    const [darkMode, setDarkMode] = useState(() => false);
    const [viewMode, setViewMode] = useState(VIEW_MODE.both); // 0 = mail, 1 = both, 2 = code
    const subBrandInfo = find(brandConfig.brands[brand].subBrands, { key: subBrand });
    const currentCategoryKey = category?.toLowerCase();
    const matchingCategory = first(
        find(categories, ([, categoryKey]) => currentCategoryKey === categoryKey)
    );

    return (
        <ScopedTheme>
            <Wrapper>
                <BreadCrumbs
                    crumbs={filter([
                        {
                            to: `${privatePathPrefix}${dirPath(brand)}`,
                            title: brandConfig.brands[brand].displayName
                        },
                        subBrandInfo && {
                            to: `${privatePathPrefix}${dirPath(brand, subBrand)}`,
                            title: subBrandInfo?.displayName
                        },
                        matchingCategory && {
                            title: matchingCategory,
                            to: `${privatePathPrefix}${dirPath(
                                brand,
                                subBrand
                            )}#${currentCategoryKey}`
                        },
                        { to: `${privatePathPrefix}`, title, current: true }
                    ])}
                    orderNav={{ nextSlug, prevSlug, order }}
                />

                <PreviewBar
                    {...{
                        viewMode,
                        setViewMode,
                        darkMode,
                        setDarkMode,
                        deployedOn,
                        deployedBy,
                        tags,
                        html
                    }}
                />

                <div
                    className={`grid ${
                        viewMode === VIEW_MODE.both ? "grid-cols-2" : "grid-cols-1"
                    } h-full bg-gray-700`}
                >
                    <div
                        className={`h-full ${viewMode <= VIEW_MODE.both ? "" : "hidden"} ${
                            viewMode === VIEW_MODE.both ? "border-r-8 border-black" : ""
                        }`}
                    >
                        <iframe
                            title="Code preview"
                            src={`${privatePathPrefix}/${slug}rendered`}
                            style={{
                                height: `100%`,
                                width: `100%`,
                                border: "0px #fff hidden",
                                background: "#fff"
                            }}
                            name="myiFrame"
                            scrolling="yes"
                            frameBorder="0"
                            marginHeight="0px"
                            marginWidth="0px"
                            height="100%"
                            width="100%"
                            allowFullScreen={true}
                        />
                    </div>

                    <div className={viewMode >= VIEW_MODE.both ? "" : "hidden"}>
                        <iframe
                            id="codePreview"
                            title="Code preview"
                            src={`${privatePathPrefix}/${slug}code`}
                            style={{
                                height: `100%`,
                                width: `100%`,
                                border: "0px #fff hidden",
                                background: "#fff"
                            }}
                            name="myiFrame"
                            scrolling="yes"
                            frameBorder="0"
                            marginHeight="0px"
                            marginWidth="0px"
                            height="100%"
                            width="100%"
                            allowFullScreen={true}
                        />
                    </div>
                </div>
            </Wrapper>
        </ScopedTheme>
    );
};

EmailPreviewUI.propTypes = {
    pageContext: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        brandConfig: PropTypes.shape({
            brands: PropTypes.object.isRequired
        }).isRequired,
        nextSlug: PropTypes.string,
        prevSlug: PropTypes.string,
        order: PropTypes.number
    }).isRequired,
    data: PropTypes.shape({
        mjml: PropTypes.object.isRequired
    }).isRequired
};

export const query = graphql`
    query($slug: String!) {
        mjml(fields: { slug: { eq: $slug } }) {
            html
            name
            brand
            subBrand
            frontmatter {
                title
                tags
                deployedOn
                deployedBy
                tags
                category
            }
        }
    }
`;

export default EmailPreviewUI;
